<template>
	<div>
		<div class="img">
			<img :src="userInfo.pic ? userInfo.pic : '../../assets/moren_touxiang.png'" alt="" />
		</div>
		<humanface type="2" ref="chind"></humanface>
		<!-- <recordtime type="4" record="1"> </recordtime> -->
		<writetime type="4"></writetime>
		<div class="username img">学员：{{ tel }}</div>
		<div class="examination">
			<div class="item1">
				<img src="../../assets/exam1.svg" alt="" />
				综合练习
			</div>
			<div class="item2" @click="tab">去考试</div>
		</div>
		<div class="examination">
			<div class="item1">
				<img src="../../assets/exam2.svg" alt="" />
				历史考试
			</div>
			<div class="item2" @click="go">查看</div>
		</div>
		<el-dialog top="45vh" title="提示" :visible.sync="dialogVisible" width="30%" center>
			<div class="ssss">
				<span class="sss" v-html="val"></span>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="goexam">确 定</el-button>
			</span>
		</el-dialog>
		<div style="height: 500px"></div>
	</div>
</template>

<script>
	import {
		mapState,
		mapActions
	} from "vuex";
	export default {
		computed: {
			...mapState({
				classid: (state) => state.classid,
				userInfo: (state) => state.userInfo,
				school: (state) => state.school,
				det: (state) => state.det,
				details: (state) => state.details,

				studyType: (state) => state.studyType,
			}),
		},
		data() {
			return {
				val: "",
				tel: "",
				dialogVisible: false,
			};
		},
		mounted() {
			var tel = String(this.userInfo.phone);
			var dh = tel.substr(0, 3) + "******" + tel.substr(8);
			this.tel = dh;
		},
		methods: {
			...mapActions([
				"getMockCover",
				"getcomprehensiveCover_x",
				"getknowledgeList_x",
				"getstudyReport_x",
				"getVideoSpeed",
				"getFaceRecognition",
			]),
			//获取当前前后日期
			getNextDate(date, day) {
				var dd = new Date(date);
				// 对于day一定要Number
				dd.setDate(dd.getDate() + Number(day));
				var y = dd.getFullYear();
				var m =
					dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
				var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
				return y + "-" + m + "-" + d;
			},
			//查看考试记录
			go() {
				this.$router.push("/Layoutstudy/testhistory");
			},
			// 统一进入考试人脸判断/日期判断
			ks() {
				if (this.details.face == 1) {
					this.getFaceRecognition({
						student_id: this.userInfo.id,
						genre: "2", //1. 学习核验 2. 考试核验
						class_id: this.details.id,
					}).then((res) => {
						if (res.errorCode == 399) {
							this.$refs.chind.control();
						} else if (res.errorCode == 201) {
							this.$message({
								message: res.message,
								type: "warning",
							});
						} else {
							var than = this;
							this.getcomprehensiveCover_x({
								class_id: this.classid, //班级id
								student_id: this.userInfo.id, //学员id
							}).then((res) => {
								if (res.errorCode) {
									this.$message({
										message: res.message,
										type: "warning",
									});
									return;
								}
								if (res.responseBody.remaining_times == 0) {
									this.$message({
										message: "本次培训考试次数已达到上限!",
										type: "warning",
									});
									return;
								}
								if (res.responseBody.exam_time != "~") {
									var myDate = new Date();
									let Year = myDate.getFullYear(); //获取完整的年份(4位,1970-????)
									let Month = myDate.getMonth() + 1; //获取当前月份(0-11,0代表1月)
									let date = myDate.getDate(); //获取当前日(1-31)
									if (Month.toString().length == 1) {
										Month = "0" + Month;
									}
									if (date.toString().length == 1) {
										date = "0" + date;
									}
									let time = `${Year}-${Month}-${date}`;
									let str;
									str = res.responseBody.exam_time.split("~")[0].substring(0, 10);
									let end = res.responseBody.exam_time
										.split("~")[1]
										.substring(0, 10);
									let flag;
									let flag1;
									let arr;

									if (str <= time) {
										flag = true;
									} else {
										flag = false;
									}
									if (time <= end) {
										flag1 = true;
									} else {
										flag1 = false;
									}
									if (flag === flag1) {
										arr = true;
									} else {
										arr = false;
									}

									if (!arr) {
										// this.$vux.confirm.show({
										//   title: "提示",
										//   content: "考试时间</br>" + str + "至" + end,
										// });
										this.$confirm("考试时间" + str + "至" + end, "提示", {
												confirmButtonText: "确定",
												cancelButtonText: "取消",
												type: "warning",
											})
											.then(() => {})
											.catch(() => {});
									} else {
										this.dialogVisible = true;
										this.val =
											str +
											"至" +
											end +
											"</br>出题类型:" +
											res.responseBody.title +
											"</br>总题量:" +
											res.responseBody.number +
											"</br>总分:" +
											res.responseBody.total_score +
											"</br>合格分:" +
											res.responseBody.pass_score +
											"</br>考试剩余次数:" +
											res.responseBody.remaining_times +
											"次";
									}
								} else {
									this.dialogVisible = true;
									this.val =
										"出题类型:" +
										res.responseBody.title +
										"</br>总题量:" +
										res.responseBody.number +
										"</br>总分:" +
										res.responseBody.total_score +
										"</br>合格分:" +
										res.responseBody.pass_score +
										"</br>考试剩余次数:" +
										res.responseBody.remaining_times +
										"次";
								}
							});
						}
					});
				} else {
					var than = this;
					this.getcomprehensiveCover_x({
						class_id: this.classid, //班级id
						student_id: this.userInfo.id, //学员id
					}).then((res) => {
						if (res.errorCode) {
							this.$message({
								message: res.message,
								type: "warning",
							});
							return;
						}
						if (res.responseBody.remaining_times == 0) {
							this.$message({
								message: "本次培训考试次数已达到上限!",
								type: "warning",
							});
							return;
						}
						if (res.responseBody.exam_time != "~") {
							var myDate = new Date();
							let Year = myDate.getFullYear(); //获取完整的年份(4位,1970-????)
							let Month = myDate.getMonth() + 1; //获取当前月份(0-11,0代表1月)
							let date = myDate.getDate(); //获取当前日(1-31)
							if (Month.toString().length == 1) {
								Month = "0" + Month;
							}
							if (date.toString().length == 1) {
								date = "0" + date;
							}
							let time = `${Year}-${Month}-${date}`;
							let str;
							str = res.responseBody.exam_time.split("~")[0].substring(0, 10);
							let end = res.responseBody.exam_time.split("~")[1].substring(0, 10);
							let flag;
							let flag1;
							let arr;

							if (str <= time) {
								flag = true;
							} else {
								flag = false;
							}
							if (time <= end) {
								flag1 = true;
							} else {
								flag1 = false;
							}
							if (flag === flag1) {
								arr = true;
							} else {
								arr = false;
							}

							if (!arr) {
								// this.$vux.confirm.show({
								//   title: "提示",
								//   content: "考试时间</br>" + str + "至" + end,
								// });
								this.$confirm("考试时间" + str + "至" + end, "提示", {
										confirmButtonText: "确定",
										cancelButtonText: "取消",
										type: "warning",
									})
									.then(() => {})
									.catch(() => {});
							} else {
								this.dialogVisible = true;
								this.val =
									str +
									"至" +
									end +
									"</br>出题类型:" +
									res.responseBody.title +
									"</br>总题量:" +
									res.responseBody.number +
									"</br>总分:" +
									res.responseBody.total_score +
									"</br>合格分:" +
									res.responseBody.pass_score +
									"</br>考试剩余次数:" +
									res.responseBody.remaining_times +
									"次";
							}
						} else {
							this.dialogVisible = true;
							this.val =
								"出题类型:" +
								res.responseBody.title +
								"</br>总题量:" +
								res.responseBody.number +
								"</br>总分:" +
								res.responseBody.total_score +
								"</br>合格分:" +
								res.responseBody.pass_score +
								"</br>考试剩余次数:" +
								res.responseBody.remaining_times +
								"次";
						}
					});
				}
			},
			// 对新班旧班进行逻辑判断
			tab() {
				// this.ks();
				// return;
				// if (this.userInfo.phone == 15000000010 && this.det.id == 2810) {
				//   this.ks();
				//   return;
				// }
				// let classtype = window.localStorage.getItem("classtype");
				// let classhour = window.localStorage.getItem("classhour");
				// let ksSpeed = window.localStorage.getItem("ksSpeed");
				
				// console.log('========================================')
				// console.error(classtype,ksSpeed);
				// if (classtype == 3) {
				// 	if(ksSpeed == '100%') {
				// 		this.ks();
				// 		return
				// 	} else {
				// 		this.$message({
				// 			message: "亲，请完成计划课时后参加考试！",
				// 			type: "warning",
				// 		});
				// 	}
				// }
				let sendtr = this.det.end.substring(0, 10).replace(/-/g, "/");
				let end_exam = false;
				var end_d1 = Date.parse(sendtr); // 结班时间
				var end_d2 = Date.parse("2022/07/22"); // 新逻辑时间
				if (end_d1 > end_d2) {
					end_exam = true;
				} else {
					end_exam = false;
				}
				if (end_exam) {
					this.getstudyReport_x({
						student_id: this.userInfo.id,
						class_id: this.det.id,
					}).then((res) => {
						if (res.responseBody.classHour == "100%") {
							this.getknowledgeList_x({
								class_id: this.det.id, //班级id
								student_id: this.userInfo.id, //学员id
								type: "1", //1. 视频 2. 音频
								page: 1,
								pageSize: 1,
							}).then((ress) => {
								this.getVideoSpeed({
									student_id: this.userInfo.id, //学员id
									class_id: this.det.id, //班级id
								}).then((resss) => {
									if (resss.successCode == 200) {
										if (
											resss.responseBody.finished_count >=
											ress.responseBody.knowledge.total
										) {
											this.ks();
										} else {
											this.$message({
												message: "亲，请将视频课程学完后参加考试！",
												type: "warning",
											});
										}
									}
								});
							});
						} else {
							this.$message({
								message: "亲，请完成计划课时后参加考试！",
								type: "warning",
							});
						}
					});
					///////
				} else {
					let str = this.det.start.substring(0, 10).replace(/-/g, "/");
					let exam = false;
					var d1 = Date.parse(str); // 开班时间
					var d2 = Date.parse("2022/07/20"); // 新逻辑时间
					if (d1 > d2) {
						exam = true;
					} else {
						exam = false;
					}
					if (exam) {
						this.getstudyReport_x({
							student_id: this.userInfo.id,
							class_id: this.det.id,
						}).then((res) => {
							if (res.responseBody.classHour == "100%") {
								this.getknowledgeList_x({
									class_id: this.det.id, //班级id
									student_id: this.userInfo.id, //学员id
									type: "1", //1. 视频 2. 音频
									page: 1,
									pageSize: 1,
								}).then((ress) => {
									this.getVideoSpeed({
										student_id: this.userInfo.id, //学员id
										class_id: this.det.id, //班级id
									}).then((resss) => {
										if (ress.successCode == 200) {
											if (
												resss.responseBody.finished_count >=
												ress.responseBody.knowledge.total
											) {
												this.ks();
											} else {
												this.$message({
													message: "亲，请将视频课程学完后参加考试！",
													type: "warning",
												});
											}
										}
									});
								});
							} else {
								this.$message({
									message: "亲，请完成计划课时后参加考试！",
									type: "warning",
								});
							}
						});
					} else {
						let str = this.det.start.substring(0, 10).replace(/-/g, "/");
						let exam = false;
						var d1 = Date.parse(str); // 开班时间
						var d2 = Date.parse("2022/06/13"); // 新逻辑时间
						if (d1 > d2) {
							exam = true;
						} else {
							exam = false;
						}
						if (exam) {
							this.getstudyReport_x({
								student_id: this.userInfo.id,
								class_id: this.det.id,
							}).then((res) => {
								if (res.responseBody.classHour == "100%") {
									this.ks();
								} else {
									this.$message({
										message: "亲，请完成计划课时后参加考试！",
										type: "warning",
									});
								}
							});
							// if (this.det.study_config == 1) {
							//   this.getknowledgeList_x({
							//     class_id: this.det.id, //班级id
							//     student_id: this.userInfo.id, //学员id
							//     type: "1", //1. 视频 2. 音频
							//     page: 1,
							//     pageSize: 1,
							//   }).then((ress) => {
							//     this.getVideoSpeed({
							//       student_id: this.userInfo.id, //学员id
							//       class_id: this.det.id, //班级id
							//     }).then((resss) => {
							//       if (ress.successCode == 200) {
							//         if (
							//           resss.responseBody.finished_count >=
							//           ress.responseBody.knowledge.total
							//         ) {
							//           this.ks();
							//         } else {
							//           this.$message({
							//             message: "亲，请将视频课程学完后参加考试！",
							//             type: "warning",
							//           });
							//         }
							//       }
							//     });
							//   });
							// } else {
							//   this.getstudyReport_x({
							//     student_id: this.userInfo.id,
							//     class_id: this.det.id,
							//   }).then((res) => {
							//     if (res.responseBody.classHour == "100%") {
							//       this.ks();
							//     } else {
							//       this.$message({
							//         message: "亲，请完成计划课时后参加考试！",
							//         type: "warning",
							//       });
							//     }
							//   });
							// }
						} else {
							this.ks();
						}
					}
				}

				// if (this.studyType == 2) {
				//   this.$vux.toast.show({
				//     type: "cancel",
				//     text: "请购买课程",
				//   });
				//   return false;
				// }
				// end
			},
			//进入考试页面
			goexam() {
				this.$router.push({
					path: "/Layoutstudy/writingexercise",
					query: {
						type: 1,
						title: "综合测试",
						up: "考试",
					},
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.img {
		display: flex;
		justify-content: center;

		img {
			border-radius: 50%;
			text-align: center;
			width: 80px;
			height: 80px;
		}
	}

	.ssss {
		text-align: center;
	}

	.sss {
		display: inline-block;
		text-align: center;
	}

	.username {
		margin-top: 15px;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #7c7c7c;
	}

	.examination {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 100px;
		border-bottom: 1px solid #e1e1e1;

		.item1 {
			font-size: 18px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: #222222;

			img {
				vertical-align: middle;
				margin-right: 15px;
			}
		}

		.item2 {
			cursor: pointer;
			width: 100px;
			height: 30px;
			line-height: 25px;
			text-align: center;
			background: #e9f3ff;
			border: 2px solid #137cfb;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #137cfb;
		}
	}
</style>